

<template>
  <layout class="backglass">
    <div  >
      <b-col  style="margin-top:2vh;">
        <b-row >
          <b-col class="text-center" style="padding: 0px;margin: 0px;">
            <b-row class="cardglass mx-1 my-3"> <b-col  class="dashiconsmain text-center " @click="gotoprofile()"><b-row ><b-col> <i   class="ri-user-3-line align-middle dashicons text-center"></i></b-col></b-row><b-row><b-col ><h6 class="texticon"> Profil</h6></b-col></b-row></b-col></b-row>
            <b-row class="cardglass mx-1 my-3"> <b-col class="dashiconsmain text-center " @click="gotoclassroutine()"><b-row><b-col> <i   class="ri-calendar-event-line align-middle dashicons"></i></b-col></b-row><b-row><b-col > <h6 class="texticon">Calendrier</h6> </b-col></b-row></b-col></b-row>
            <b-row class="cardglass mx-1 my-3"> <b-col class="dashiconsmain text-center " @click="gotoexam()"><b-row><b-col> <i   class="ri-todo-line align-middle dashicons"></i></b-col></b-row><b-row><b-col > <h6 class="texticon"> Examens</h6></b-col></b-row></b-col></b-row>
          </b-col>
          <b-col class="text-center" style="padding: 0px;margin: 0px;">
            <b-row  class="cardglass mx-1 my-3"> <b-col  class="dashiconsmain text-center " @click="gotoresult()"><b-row><b-col> <i   class="ri-file-chart-line align-middle  dashicons"></i></b-col></b-row><b-row><b-col>  <h6  class="texticon"> Résultats</h6></b-col></b-row></b-col></b-row>
            <b-row class="cardglass mx-1 my-3"> <b-col  class="dashiconsmain text-center " @click="gotopayment()"><b-row><b-col> <i   class="ri-wallet-3-line align-middle  dashicons"></i></b-col></b-row><b-row><b-col > <h6  class="texticon"> Paiements</h6> </b-col></b-row></b-col></b-row>
            <b-row class="cardglass mx-1 my-3"> <b-col  class="dashiconsmain text-center " @click="gotonotifications()"><b-row><b-col> <i   class="ri-notification-3-line align-middle  dashicons"><span v-if="paymentnotification != null" class="badge">{{paymentnotification}}</span></i></b-col></b-row><b-row><b-col >  <h6 class="texticon"> Notifications</h6></b-col></b-row></b-col></b-row>
           </b-col>
          <b-col class="text-center" style="padding: 0px;margin: 0px;">
            <b-row class="cardglass mx-1 my-3"> <b-col  class="dashiconsmain text-center " @click="gotogroup()"><b-row><b-col> <i   class="ri-building-line align-middle  dashicons"></i></b-col></b-row><b-row><b-col > <h6 class="texticon" > Groupes</h6> </b-col></b-row></b-col></b-row>
            <b-row class="cardglass mx-1 my-3"> <b-col  class="dashiconsmain text-center " @click="gotoonlinecourse()"><b-row><b-col> <i   class="ri-mac-line align-middle  dashicons"></i></b-col></b-row><b-row><b-col > <h6  class="texticon"> Cours en ligne</h6> </b-col></b-row></b-col></b-row>
            <b-row class="cardglass mx-1 my-3"> <b-col  class="dashiconsmain text-center " @click="logoutFun()"><b-row><b-col> <i   class="ri-logout-circle-line align-middle  dashicons"></i></b-col></b-row><b-row><b-col > <h6  class="texticon"> Se déconnecter</h6> </b-col></b-row></b-col></b-row>
           </b-col>
        </b-row>
      </b-col>
    </div>
  </layout>
</template>
<script>
import Layout from "../../layouts/main";
import { paymentnotificationMethods ,
  authFackMethods,} from "@/state/helpers";
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
  },
  data() {
    return {
     
    };
  },
  created() {
    this.Student = JSON.parse(localStorage.getItem("user"));
    this.getPaymentNot({ id: this.Student.id });
  },
  computed: {
    paymentnotification() {
      return this.$store
        ? this.$store.state.paymentnotification.PaymentNotification.filter(x=>x.seen == 0 || x.seen == false).length
        : null;
    },
  },
  methods:{
    ...paymentnotificationMethods,
    ...authFackMethods,
    gotoprofile(){
      this.$router.push({
        path: `/profile/`
      });
    },
    gotogroup(){
      this.$router.push({
        path: `/group/` 
      });
    },
    gotoclassroutine(){
      this.$router.push({
        path: `/classroutine/` 
      });
    },
    gotopayment(){
      this.$router.push({
        path: `/payment/` 
      });
    },
    gotoexam(){
      this.$router.push({
        path: `/exam/` 
      });
    },
    gotonotifications(){
      this.$router.push({
        path: `/notifications/` 
      });
    },
    
    gotoresult(){
      this.$router.push({
        path: `/result/` 
      });
    },
    gotoonlinecourse(){
      this.$router.push({
        path: `/onlinecourse/` 
      });
    },
    logoutFun(){
      this.logout()
    }
  }
};
</script>

<style scoped>
.texticon{
  font-size:0.8rem;
  color: rgb(255, 255, 255);
}
.dashicons{
  color:rgb(255, 255, 255);
  font-size:50px;
}
.dashiconsmain{
  height: 150px;
  width: 10vw;
  margin-top: 30px;

}
@media only screen and (min-width: 600px) {
  .dashicons {   
  font-size:70px;
  }
  .texticon {   
    font-size:1.0rem;
  }
}
.badge {
  background-color: rgb(255, 132, 0);
  color: white;
  position:absolute;
  font-size:16px;
  height:30px;
  width:30px;
  padding: 4px 8px;
  margin-top:10px;
  margin-right: 10px;
  text-align: center;
  border-radius: 55px;
}
.backglass{
  height: 100vh;
 /* background-image: linear-gradient(360deg, #fdfbfb 5%, #5a0000 35%);
*/
  background-image: url("../../../assets/images/bg.png");
  background-repeat: repeat-x;
  background-origin: content-box;
}
.cardglass{
  background: rgba(255, 255, 255, 0.165);
box-shadow: 0 8px 32px 0 rgba(180, 101, 101, 0.37);
backdrop-filter: blur( 0px );
-webkit-backdrop-filter: blur( 0px );
border-radius: 10px;
}
.cardglass:hover{
  background: rgba(173, 3, 3, 0.165);
box-shadow: 0 8px 32px 0 rgba(209, 203, 203, 0.57);
backdrop-filter: blur( 0px );
-webkit-backdrop-filter: blur( 0px );
border-radius: 10px;
}
</style>